<template>
  <div class="page-container text-white">
    <div style="display: flex; gap: 1em; margin-bottom: 3em;">
      <material-button text="Gestisci Ruoli" type="info" style="width: 150px;" :class="{ disabled: view != 'rolesManagement' }" @click="goToRoleManagement()" />
      <material-button text="Assegna Ruoli" type="info" style="width: 150px;" :class="{ disabled: view != 'roleAssignment' }" @click="goToRoleAssignment()" />
    </div>
  
    <div v-if="view == 'rolesManagement'" class="top-header">
      <searchBar :placeholder="'Cerca o inserisci il nome di un nuovo ruolo per crearlo'" style="margin-right: 15px" :search="filters.role" @update:search="filters.role = $event" />
      <material-button :class="{'disabled':!filters.role }" text="Nuovo Ruolo" type="info" style="width: 150px; float: right;" @click="createNewRole()" />
    </div>

    <div v-if="view == 'roleAssignment'" class="top-header">
      <searchBar placeholder="Inserisci l'email dell'utente da ricercare" style="margin-right: 15px" :search="filters.email" @update:search="filters.email = $event" />
      <material-button
        v-if="!userActiveRolesChanged()"
        :class="{'disabled': !isAValidMail || !filters.email}"
        text="Cerca"
        type="info"
        style="width: 150px; float: right;"
        @click="getUserRoles()"
      />
      <material-button v-if="userActiveRolesChanged()" text="Salva" type="warning" style="width: 150px; float: right;" @click="saveUserRoles()" />
    </div>

    <div v-if="view == 'rolesManagement'" class="scroll">
      <table
        v-for="role in roles.filter(r => r.roleName.toLowerCase().includes(filters.role.toLowerCase()))"
        :id="'rolediv_' + role.id"
        :key="role.id"
        class="panel-table"
        cellspacing="0"
        style="overflow: visible"
      >
        <thead class="candidateThead" :class="{ '--isOpen': role.opened }" @click="onRoleRowClicked(role)" @keydown.enter.prevent="">
          <th>{{ role.roleName }}</th>
          <material-button v-if="role.updated" text="Aggiorna" type="warning" style="scale: 80%; font-size: 120%;" @click.stop="updateRole(role)" />
          <th class="th-chevron">
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path fill="white" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </svg>
          </th>
        </thead>
        <tbody class="table-body">
          <slot>
            <tr v-if="role.opened" class="collapsible-content">
              <div class="role-content">
                <div class="add-user-bar">
                  <searchBar
                    style="margin-right: 15px"
                    placeholder="Cerca utente per email"
                    :search="search"
                    @update:search="search = $event"
                    @keydown.enter.prevent="searchUser(role)"
                  />
                  <material-button text="Aggiungi utente" type="info" @click="searchUser(role)" />
                </div>

                <div class="role-users">
                  <div v-if="!role.authorizationUserRoleList.length">
                    Nessun utente associato al ruolo
                  </div>
                  <div v-for="user in role.authorizationUserRoleList" :key="user.id" class="user-box">
                    <div class="flex flex-column">
                      <div>
                        {{ user.firstName }} {{ user.lastName }}
                      </div>
                      <div>
                        {{ user.email }}
                      </div>
                    </div>
                    <font-awesome-icon class="remove-user-icon" icon="trash-can" @click="openDeleteDialog(role.id, user.uid)" />
                  </div>
                </div>

                <div v-if="role.id !== 1" class="cards">
                  <div v-for="privilege in privileges" :key="privilege.id" class="card" :class="{ active: role.authorizationRolePrivilegeList.find(f => f.authorizationPrivilege.id === privilege.id) }" @click.stop="onPrivilegeChecked(role.id, privilege.id)">
                    <input :checked="role.authorizationRolePrivilegeList.find(f => f.authorizationPrivilege.id === privilege.id)" :disabled="hasParentChecked(role)" type="checkbox" style="margin-right: 5px">
                    {{ privilege.description }}
                  </div>
                </div>
                <material-button v-if="!role.isSystemRole" text="Elimina" type="danger" style="float: right" @click.stop="deleteRole(role)" />
              </div>
            </tr>
          </slot>
        </tbody>
      </table>
    </div>

    <div v-if="view == 'roleAssignment'" class="scroll" style="padding: 0;">
      <div v-if="userFinded" class="cards" style="width: 100%;">
        <div 
          v-for="role in roles.filter(r => r.roleName.toLowerCase().includes(filters.role.toLowerCase()))"
          :key="role.id"
          :class="{ active: userActiveRolesIds.find(f => f === role.id) }"
          class="card"
          @click.stop="onRoleChecked(role.id)"
        >
          <input
            :checked="userActiveRolesIds.find(f => f === role.id)"
            type="checkbox"
            style="margin-right: 5px"
          >
          {{ role.roleName }}
        </div>
      </div>
      <div v-else>
        Nessun utente selezionato
      </div>
    </div>
  </div>

  <Dialog ref="deleteDialog" title="Rimovere l'utente dal ruolo?" button-text="Rimuovi" @buttonAction="removeUserFromRole" />
</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue'
import { navbarStore } from '@/stores/navbar'
import searchBar from '../components/searchBarList.vue'
import { sbapibackoffice } from '@/api'
import { spinnerStore } from '@/stores/spinner'
import Dialog from '../components/Dialog.vue'

const navbar = navbarStore()
const spinner = spinnerStore()
const toast = inject('$toast')
const isAValidMail = ref(false)

const filters = ref({
  role: '',
  email: ''
})

watch(() => filters.value.email, (newValue) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  isAValidMail.value = emailRegex.test(newValue)
})

const goToRoleManagement = () => {
  view.value = 'rolesManagement'
  getRoles()
}

const goToRoleAssignment = () => {
  view.value = 'roleAssignment'
  userFinded.value = null
  userActiveRolesIds.value = []
  originalUserActiveRolesIds.value = []
}

const privileges = ref([])
const getPrivileges = () => {
  sbapibackoffice
    .get('/security/privileges').catch((e) => {
      console.log(e)
    }).then(({ data }) => {
      privileges.value = data
    })
}
const onPrivilegeChecked = (roleId, privilegeId) => {
  const privilege = privileges.value.find(f => f.id == privilegeId)
  const role = roles.value.find(f => f.id == roleId)

  role.updated = true
  const hasRoleSelected = role.authorizationRolePrivilegeList.find(f => f.authorizationPrivilege.id === privilege.id)

  // if the role was selected, remove it
  if (hasRoleSelected) {
    role.authorizationRolePrivilegeList = role.authorizationRolePrivilegeList.filter(f => f.authorizationPrivilege.id !== privilege.id)
  } else {
    role.authorizationRolePrivilegeList.push({
      authorizationPrivilege: privilege
    })

    // if the privilege doesn't have parents, do nothing
    if (!privilege.parentCodes) return

    // only if the child has been checked, check the parent
    for (const parentCode of privilege.parentCodes.split(',')) {
      const parent = privileges.value.find(f => f.code == parentCode)

      if (hasRoleSelected) {
        role.authorizationRolePrivilegeList = role.authorizationRolePrivilegeList.filter(f => f.authorizationPrivilege.id !== parent.id)
      } else {
        // push the role only if not exits
        if (!role.authorizationRolePrivilegeList.find(f => f.authorizationPrivilege.id === parent.id)) {
          role.authorizationRolePrivilegeList.push({
            authorizationPrivilege: parent
          })
        }
      }
    }
  }
}
const onRoleChecked = (roleId) => {
  const role = roles.value.find(f => f.id == roleId)

  const hasRoleSelected = userActiveRolesIds.value.find(f => f === role.id)

  // if the role was selected, remove it
  if (hasRoleSelected) {
    userActiveRolesIds.value = userActiveRolesIds.value.filter(f => f !== role.id)
  } else {
    userActiveRolesIds.value.push(role.id)
  }
}
const hasParentChecked = (/*role*/) => {
  // // check each role if it is selected, if yes, return true
  // for (const rolePrivilege of role.authorizationRolePrivilegeList) {    
  //   const privilege = rolePrivilege.authorizationPrivilege
  //   console.log(privilege)
  //   // if (rolePrivilege.authorizationRolePrivilegeList.parentCodes) {
  //   //   console.log(privilege.parentCodes)
  //   //   for (const parentCode of privilege.parentCodes.split(',')) {
  //   //     if (rolePrivilege.authorizationPrivilege.roleName === parentCode) {
  //   //       console.log('should be disabled', privilege.roleName, parentCode)
  //   //       return true
  //   //     }
  //   //   }
  //   // }
  // }

  return false
}

// check if userActiveRolesIds are the same of originalUserActiveRolesIds.value
const userActiveRolesChanged = () => {
  if (userActiveRolesIds.value.length != originalUserActiveRolesIds.value.length) return true

  for (const roleId of userActiveRolesIds.value) {
    if (!originalUserActiveRolesIds.value.find(f => f === roleId)) return true
  }

  return false
}

const roles = ref([])
const getRoles = () => {
  spinner.show()
  sbapibackoffice
    .get('/security/roles').catch((e) => {
      console.log(e)
    }).then(({ data }) => {
      roles.value = data
    }).finally(() => {
      spinner.hide()
    })
}
const updateRole = (role) => {
  toast.clear()
  console.log(role)
  spinner.show()
  sbapibackoffice
    .put('/security/role', {
      roleName: role.roleName,
      authorizationRolePrivilegeIds: role.authorizationRolePrivilegeList?.map(a => a.authorizationPrivilege.id) ?? [],
      authorizationRoleUserIds: role.authorizationUserRoleList?.map(a => a.uid) ?? []
    }).catch((e) => {
      console.log(e)
      toast.error('Errore durante l\'aggiornamento')
    }).then(() => {
      toast.success('Aggiornamento effettuato')
    }).finally(() => {
      spinner.hide()
    })
}

const deleteRole = (role) => {
  toast.clear()
  spinner.show()
  sbapibackoffice
    .delete(`/security/role?roleId=${role.id}`).catch((e) => {
      console.log(e)
      toast.error('Errore durante l\'eliminazione')
    }).then(() => {
      toast.success('Eliminazione effettuata')
      getRoles()
    }).finally(() => {
      spinner.hide()
    })
}

const onRoleRowClicked = (role) => {
  role.opened = !role.opened
  document.getElementById('rolediv_' + role.id).scrollIntoView()

  // close all other roles
  for (const r of roles.value) {
    if (r.id != role.id) {
      r.opened = false
    }
  }
}

const deleteDialog = ref(null)
const userRoleToRemove = {
  uid: null,
  roleId: null
}
const openDeleteDialog = (roleId, uid) => {
  userRoleToRemove.uid = uid
  userRoleToRemove.roleId = roleId
  deleteDialog.value.open()
}
const removeUserFromRole = () => {
  toast.clear()
  const role = roles.value.find(f => f.id == userRoleToRemove.roleId)
  role.authorizationUserRoleList = role.authorizationUserRoleList.filter(f => f.uid != userRoleToRemove.uid)
  role.updated = true
  deleteDialog.value.close()
  toast.show('Aggiorna il ruolo per salvare le modifiche')
}

const search = ref('')
const searchUser = (role) => {
  toast.clear()
  spinner.show()
  sbapibackoffice
    .post('/search-cving-users', { search: search.value })
    .then(({ data }) => {
      if (data.length == 0) {
        toast.error('Nessun utente trovato')
        return
      } else if (data.length > 1) {
        // if email is exact match, use it else use the first of the list
        const exactMatch = data.find(f => f.email === search.value)
        if (exactMatch) {
          data = [exactMatch]
        }
      }

      role.authorizationUserRoleList.push({
        email: data[0].email,
        firstName: data[0].first_name,
        lastName: data[0].last_name,
        uid: data[0].uid
      })
      role.updated = true
      toast.show('Aggiorna il ruolo per salvare le modifiche')
      search.value = ''
    })
    .catch(err => {
      toast.error('Qualcosa è andato storto', err)
    })
    .finally(spinner.hide)
}

const userActiveRolesIds = ref([])
const originalUserActiveRolesIds = ref([]) // used to mantain the original state of the user roles
const userFinded = ref(null)

const getUserRoles = () => {
  toast.clear()
  if (!isAValidMail.value) {
    const email = filters.value.email
    const errorMessage = email ? `Verifica l'indirizzo email: "${email}" non è valido per la ricerca.` : 'Inserisci un indirizzo email valido per effettuare una ricerca.'
    toast.error(errorMessage)
    return
  }

  spinner.show()
  sbapibackoffice
    .post('/search-cving-users', { search: filters.value.email })
    .then(({ data }) => {
      if (data.length == 0) {
        toast.error('Nessun utente trovato')
        userActiveRolesIds.value = []
        // originalUserActiveRolesIds.value = []
        Object.assign(originalUserActiveRolesIds.value, [])
        userFinded.value = null
        return
      } else if (data.length > 1) {
        // if email is exact match, use it else use the first of the list
        const exactMatch = data.find(f => f.email === filters.value.email)
        if (exactMatch) {
          data = [exactMatch]
        }
      }

      const userRoles = roles.value.filter(r => r.authorizationUserRoleList.find(f => f.uid === data[0].uid))

      if (userRoles.length !== 0) {
        userActiveRolesIds.value = userRoles.map(m => m.id)
        // originalUserActiveRolesIds.value = userActiveRolesIds.value
        Object.assign(originalUserActiveRolesIds.value, userActiveRolesIds.value)
      }

      userFinded.value = data[0].uid
    })
    .catch(err => {
      console.error(err)
      toast.error('Qualcosa è andato storto', err)
    })
    .finally(spinner.hide)
}

const saveUserRoles = () => {
  const rolesRemoved = originalUserActiveRolesIds.value.filter(f => !userActiveRolesIds.value.find(r => r === f))
  const rolesAdded = userActiveRolesIds.value.filter(f => !originalUserActiveRolesIds.value.find(r => r === f))

  spinner.show()
  const promises = []

  for (const id of rolesAdded) {
    if (roles.value.filter(r => r.id === id)) {
      const roleAdded = roles.value.find(r => r.id === id)
      console.log('roleAdded', roleAdded)
      promises.push(
        sbapibackoffice.put('/security/role/', {
          roleName: roleAdded.roleName,
          authorizationRolePrivilegeIds: roleAdded.authorizationRolePrivilegeList?.map(a => a.authorizationPrivilege.id) ?? [],
          authorizationRoleUserIds: [...roleAdded.authorizationUserRoleList?.map(a => a.uid) ?? [], userFinded.value]
        })
      )
    }
  }

  for (const id of rolesRemoved) {
    if (roles.value.filter(r => r.id === id)) {
      const roleRemoved = roles.value.find(r => r.id === id)
      console.log('roleRemoved', roleRemoved)
      promises.push(
        sbapibackoffice.put('/security/role/', {
          roleName: roleRemoved.roleName,
          authorizationRolePrivilegeIds: roleRemoved.authorizationRolePrivilegeList?.map(a => a.authorizationPrivilege.id) ?? [],
          authorizationRoleUserIds: roleRemoved.authorizationUserRoleList?.filter(f => f.uid !== userFinded.value)?.map(a => a.id) ?? []
        })
      )
    }
  }
  
  Promise.all(promises)
    .then(() => {
      toast.success('Ruoli aggiornati')
      Object.assign(originalUserActiveRolesIds.value, userActiveRolesIds.value)
    })
    .catch((err) => {
      console.log(err)
      toast.error('Qualcosa è andato storto', err)
    })
    .then(() => {
      spinner.hide()
    })
}

const createNewRole = () => {
  toast.clear()
  const roleName = filters.value.role.toLowerCase()
  const isRoleNameValid = roles.value.every(r => r.roleName.toLowerCase() !== roleName)

  if (!roleName || !isRoleNameValid) {
    const errorMessage = !roleName ? 'Inserisci il nome di un ruolo per crearlo' : 'Il nome del ruolo è già stato utilizzato'
    toast.error(errorMessage)
    return
  }

  spinner.show()
  sbapibackoffice
    .post('/security/role', {
      roleName: filters.value.role,
      authorizationRolePrivilegeIds: [],
      authorizationRoleUserIds: []
    })
    .then(() => {
      getRoles()
      toast.clear()
      filters.value.role = ''
      toast.success('Ruolo creato')
    })
    .catch(err => {
      toast.error('Qualcosa è andato storto', err)
      spinner.hide()
    })
}

const view = ref('rolesManagement')

onMounted(() => {
  navbar.title = 'Ruoli'

  getPrivileges()
  getRoles()
})
</script>

<style lang="scss" scoped>
* {
  scroll-behavior: smooth;
}

.page-container {
  height: calc(100% - 50px);
  width: 100%;
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.12);
  padding: 40px;
  font-size: 14px;

  .scroll {
    padding-right: 1em;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    height: calc(100% - 65px);
  }
}

.top-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

.permissions {
  margin-top: 1em;
  color: white;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  gap: 1em;
  padding: 0 1rem;

  .permission {
    label {
      display: flex;
      align-content: center;
      align-items: center;
      gap: 1em;
    }
  }
}

table {
  thead {
    border-radius: 5px;
    padding: 0 15px !important;
    background: #1a74a3;
    top: 0;
    position: relative !important;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;

    th:first-of-type {
      display: flex;
      align-items: center;
      flex: 1;
      width: 100%;
    }

    th {
      padding: 0 !important;
      text-align: left;
      height: auto;
    }

    th.th-action {
      text-align: right;
      min-width: 30%;
    }

    th.th-chevron svg {
      transform: rotate(0deg);
      transition: transform 250ms ease-in-out;
    }

    &.--isOpen {
      th.th-chevron svg {
        transform: rotate(90deg);
        transition: transform 250ms ease-in-out;
      }
    }
  }

  .role-content {
    padding: 5px;

    .add-user-bar {
      display: flex;
      align-items: center;
    }

    .role-users {
      display: flex;
      gap: 1em;
      padding: 10px 0;
      border-bottom: 1px solid #1a74a3;
      margin-bottom: 10px;
      flex-wrap: wrap;

      .user-box {
        display: flex;
        align-items: center;
        gap: 0.5em;
        padding: 0.5em;
        border-radius: 5px;
        border: 1px solid #1a74a3;
        user-select: none;

        .remove-user-icon {
          margin: 5px;
          font-size: 1.15rem;
          color: #c84d4d;
          cursor: pointer;
          transition: all 100ms ease-in-out;

          &:hover {
            scale: 1.2;
          }
        }
      }
    }
  }
}

.panel-table {
  margin-top: 1em;
  color: white;
}

.cards {
  // max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  margin-bottom: 2em;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  .card {
    background-color: #1e90ff4d;
    color: white;
    height: 2.5rem;
    border-radius: 5px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    transition: all 250ms ease-in-out;
    user-select: none;
    cursor: pointer;

    &:hover {
      background-color: #1e90ff;
      transform: scale(1.025);
    }

    &.active {
      background-color: #1e90ff;
    }
  }
}

h2 {
  margin-bottom: 1em;
}
</style>